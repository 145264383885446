import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Software_demo from "./Software_demo";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}> </Route>
        <Route path="/About" element={<About />}> </Route>
        <Route path="/Services" element={<Services />}> </Route>
        <Route path="/Contact" element={<Contact />}> </Route>
        <Route path="/Software_demo" element={<Software_demo />}> </Route>
      </Routes>
    </Router>
  );
}
